import { Routes, Route, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import './assets/css/App.css'
import { validarSesion } from './providers/validarSesion';
import Spiner from './widgets/Spiner';
import { urlServer } from './providers/serverProvider';

import DashBoard from './pages/DashBoard'
import DashboardProductos from './pages/outlets/DashboardProducts'
import DashboardInicio from './pages/outlets/DashboardInicio';
import AgregarModificarProducto from './pages/outlets/AgregarModificarProducto'

function App() {

  const [ params, setParams ] = useSearchParams();
  const [ isLoading, setIsLoading ] = useState(true)
  

  useEffect(() => {
    let tokenInit = params.get('tempToken') ?? ''
    if (tokenInit !== ''){
      localStorage.setItem('tempToken', tokenInit)
      validarSesion()
    } else {
      const token = localStorage.getItem('tempToken') ?? ''
      if (token !== ''){
        validarSesion()
      } else {
        window.location.href = urlServer;
      }
    }
    setParams('')
    setIsLoading(false)
  }, [])

  return (
    <Routes>
      <Route path="/" element={isLoading ? <Spiner/> : <DashBoard />}>
        <Route index element={ <DashboardInicio /> }/>
        <Route
          path="productos/:categoria"
          element={<DashboardProductos />}
        />
        <Route
          path="agregar-modificar-producto/:idProducto/:categoria"
          element={<AgregarModificarProducto />}
        />
      </Route>
    </Routes>
  )
}

export default App;
