import { urlServer } from "../providers/serverProvider";

export const userAuth = async credenciales => {
    let formData = new FormData();
    for ( var key in credenciales ) {
        formData.append(key, credenciales[key]);
    }
    return await fetch(`${ urlServer }/user/auth`, {
        method: 'POST',
        body: formData,
    })
    .then((response) => response.json())
    .catch((error) => console.log(error))
}

export const getDatos = async (ruta, parametros = {}) => {
    let token = localStorage.getItem('tempToken') ?? ''
    let formData = new FormData();
    formData.append('token', token);
    for ( var key in parametros ) {
        formData.append(key, parametros[key]);
    }
    return await fetch(`${ urlServer }${ ruta }`, {
        method: 'POST',
        body: formData,
    })
    .then((response) => response.json())
    .catch((error) => console.log(error))
}

export const mutarDatos = async ({ ruta, parametros }) => {
    let token = localStorage.getItem('tempToken') ?? ''
    let formData = new FormData();
    formData.append('token', token);
    for ( var key in parametros ) {
        if (key === "file1" || key === "file2" || key === "file3" || key === "file4" || key === "file5") {
            formData.append(key, parametros[key][0]);
        } else {
            formData.append(key, parametros[key]);
        }
    }
    return await fetch(`${ urlServer }${ ruta }`, {
        method: 'POST',
        body: formData,
    })
    .then((response) => response.json())
    .catch((error) => console.log(error))
}