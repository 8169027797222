import '../assets/css/spin.css'

export default function ({title, message}) {
    return (
        <div id="spin">  
            <div className="loadingio-spinner-double-ring-yjs29i5x7wd">
                <div className="ldio-nl634peqss">
                    <div></div>
                    <div></div>
                    <div>
                        <div></div>
                    </div>
                    <div>
                        <div></div>
                    </div>
                </div>
                <p className='titulo-spinner'>{ title }</p>
                <p className='message-spinner'>{ message}</p>
            </div>
        </div>
    );
}