import { customSwall } from "../components/CustomSwal";
import { urlServer } from '../providers/serverProvider';

export const validarSesion = async _ => {

    const errors = (e) => {
        switch (e) {
            case 'Expired token':
                return 'La sesión a expirado, vuelve a ingresar tus datos'
            default:
                return e
        }
    }

    const reload = error => {
        if (error) {
            customSwall.fire({
                title: 'Error!',
                text: errors(error),
                icon: 'error',
                confirmButtonText: 'OK'
            }).then( _ => window.location.href = urlServer);
        } else {
            customSwall.fire({
                title: 'Error!',
                text: 'Ésta sesión de administrador a caducado o ya no es válida, vuelve a ingresar desde la tienda',
                icon: 'error',
                confirmButtonText: 'OK'
            }).then( _ => window.location.href = urlServer);
        }
    }

    const token = localStorage.getItem('tempToken') ?? null
    if (token === null) {
        reload('Ésta sesión de administrador a caducado, vuelve a ingresar desde la tienda')
        return
    }

    let formData = new FormData();
    formData.append('token', token);
    await fetch(`${ urlServer }/admin/valid-token`, {
        method: 'POST',
        body: formData,
    })
    .then((response) => response.json())
    .then((data) => {
        if (data.ok) {
            return true
        } else {
            reload(data.error)
        }
    })
    .catch(error => {
        reload()
    })
}